import styled, { keyframes } from "styled-components";
import { ChangeEvent, FormEvent, useState } from "react";
// context
import { useSeller } from "../../../context/seller";
// components
import GeneralInput from "../../common/GeneralInput";
import CountryInput from "../../common/CountryInput";
// types
import { Passenger } from "../../../types/common.types";
import SellerService from "../../../services/seller.service";
// icons
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useGlobal } from "../../../context/global";

export default function CreatePassenger() {
    const {setMessage} = useGlobal()
    const { setIsCreatePassengerModalOpen, setNewPassenger } = useSeller()
    const [data, setData] =  useState<Partial<Passenger>>({
        nationality: 'CL',
    })
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.name
        const value = e.target.value
        setData((prevData) => ({...prevData, [name]: value}))
    }
    function onSelectCountry(e: ChangeEvent<HTMLSelectElement>) {
        const name = e.target.name
        const value = e.target.value
        setData((prevData) => ({...prevData, [name]: value}))
    }

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const newPassenger = (await SellerService.createPassenger(data)).data
            setNewPassenger(newPassenger)
            setMessage({
                type: 'success',
                content: 'Passenger successfully created'
            })
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Main>
            <Modal onSubmit={onSubmit}>
                <h1>Create new passenger</h1>
                <GeneralInput
                label="Name"
                value={data.name || ''}
                onChange={onChange}
                placeholder="Carlos Perez"
                name="name"
                style={{marginBottom: '20px'}}
                required
                />
                <GeneralInput
                label="Email*"
                value={data.email || ''}
                onChange={onChange}
                placeholder="example@example.com"
                name="email"
                style={{marginBottom: '20px'}}
                required
                />
                <GeneralInput
                label="Phone"
                value={data.phone || ''}
                onChange={onChange}
                placeholder="+58 412-0713771"
                name="phone"
                style={{marginBottom: '20px'}}
                required
                />
                <GeneralInput
                label="Rut / Passport"
                value={data.rut_passport || ''}
                onChange={onChange}
                placeholder="XXXXXXXX-Z"
                name="rut_passport"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Hotel"
                value={data.hotel || ''}
                onChange={onChange}
                placeholder="APANATOR"
                name="hotel"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Age"
                value={data.age || ''}
                onChange={onChange}
                placeholder="18"
                name="age"
                type="number"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Remark"
                value={data.remark || ''}
                onChange={onChange}
                placeholder="Vegetarian"
                name="remark"
                style={{marginBottom: '20px'}}
                />
                <div className="locations">
                    <CountryInput
                    label="Nationality"
                    name="nationality"
                    value={data.nationality}
                    onChange={onSelectCountry}
                    />
                </div>
                <button type="submit">Create new passenger</button>
                <div className="close" title="close"
                onClick={() => setIsCreatePassengerModalOpen(false)}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.form`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
.locations {
display: flex;
gap: 20px;
margin-bottom: 20px;
}

button {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button:hover {
    color: ${props => props.theme.white};
}

button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button:hover::before {
    top: -30px;
    left: -30%;
}
`