import { useEffect, useState } from "react";
import Date from "./Date";
import List from "./List";
import { useAdmin } from "../../../context/admin";
import AdminService from "../../../services/admin.service";
import * as CommonTypes from "../../../types/common.types"

export default function Reservations() {
    const {currentDay} = useAdmin()
    const [list, setList] = useState<CommonTypes.FullTourInfo[]>([])
    useEffect(() => {
        async function getList() {
            try {
                if (!currentDay) return
                const response = await (await AdminService.listToursReservationsPassengers(currentDay)).data
                console.log(response)
                setList(response)
            } catch (error) {
                console.log(error)
            }
        }
        if (currentDay) getList()
    }, [currentDay])
    return (
        <>
        <Date/>
        <List list={list}/>
        </>
    )
}
