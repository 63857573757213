import styled, {keyframes} from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai"
import { useAdmin } from "../../../context/admin";
import * as XLSX from 'xlsx';
import React, { useState, ChangeEvent, useEffect } from "react";
import * as CommonTypes from '../../../types/common.types'
import GeneralInput from "../../common/GeneralInput";
import insert from "../../../utils/insert";
import { add } from "date-fns";
import AdminService from "../../../services/admin.service";
import CountryInput from "../../common/CountryInput";
import { useGlobal } from "../../../context/global";
import InputDropdown from "../../common/InputDropdown";


export default function ReadExcel() {
    const {setMessage} = useGlobal()
    const { setRegisterPassengersTripId, setCurrentDay, registerPassengersTripId } = useAdmin()
    const [agencyNameLink, setAgencyNameLink] = useState<string>('')
    const [agencyNameExcel, setAgencyNameExcel] = useState<string>('')
    const [data, setData] = useState<CommonTypes.PassengerExcel[]>([])
    const [token, setToken] = useState<string>('')
    const [agencies, setAgencies] = useState<CommonTypes.Agency[]>([])
    useEffect(() => {
        async function getList() {
            try {
                const response = (await AdminService.listAgencies()).data
                setAgencies(response)
            } catch (error) {
                console.log(error)
            }
        }
        getList()
    }, [])
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
          readExcel(file);
        }
      };
    
      const readExcel = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target?.result as string;
          const workbook = XLSX.read(data, { type: 'binary' });
    
          // Assuming you want to read data from the first sheet
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
    
          // Extracting names and last names from columns A and B
          const headers = ['name', 'email', 'age', 'rut_passport', 'phone', 'hotel', 'nationality', 'remark', 'traspass_price', 'tickets']
          const dataArray = XLSX.utils.sheet_to_json(sheet, { header: headers }) as any[];

          setData(dataArray)
        };
        reader.readAsBinaryString(file);
      };

      function onChange(e: ChangeEvent<HTMLInputElement>, id: number) {
        const name = e.target.name
        let value: string | number = e.target.value
        if (e.target.type === 'number') value = Number(value)
        const currentItem = data.find(item => item.__rowNum__ === id)
        const newItem = {...currentItem, [name]: value, __rowNum__: id}
        const oldList = data.filter(item => item.__rowNum__ !== id)
        const newList = insert(oldList, id, newItem)
        setData(newList)
      }

      const removeRowNumKey = (arr: CommonTypes.PassengerExcel[]) => {
        const keyToRemove = "__rowNum__";
        return arr.map(obj => {
            const { [keyToRemove]: removedKey, ...rest } = obj;
            return rest;
        });
    };

    async function addPassengers() {
        try {
            if (!registerPassengersTripId) return
            const newData = removeRowNumKey(data)
            await AdminService.traspassAddPassengers(registerPassengersTripId, agencyNameExcel, newData)
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: 1}))
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: -1}))
            setRegisterPassengersTripId(undefined)
        } catch (error: any) {
            setMessage({
                type: 'error',
                content: error.response.data
            })
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
        }
    }

    async function share() {
        try {
            if (!registerPassengersTripId) return 
            if (token) return 
            const newData = await (await AdminService.traspassCreateToken(registerPassengersTripId, agencyNameLink)).data
            setToken(newData.id)
            navigator.clipboard.writeText(`${window.location.host}/admin/addPassengers?token_id=${newData.id}`)
        } catch (error: any) {
            setMessage({
                type: 'error',
                content: error.response.data
            })
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
        }
    }
    console.log(data)
    return (
        <Main>
            <Modal>
                <span className="title">
                    Share with another agency
                </span>
                {
                    agencies.length > 0 &&
                    <InputDropdown
                    options={agencies}
                    selector="name"
                    keys={['name']}
                    label="Agency name"
                    optionDefaultHeight={35}
                    value={agencyNameLink}
                    onChange={(newValue: string) => setAgencyNameLink(newValue)}
                    />
                }
                <div>
                    <button type="submit"
                    className="create-link"
                    style={{margin: '20px 0'}}
                    onClick={share}
                    >
                        {
                            token ?
                            'Copied to clipboard'
                            :
                            'Create link'
                        }
                    </button>
                </div>
                <hr />
                <div className="data">
                    <span className="title">Agency where you're importing these passengers</span>
                    {
                    agencies.length > 0 &&
                        <InputDropdown
                        options={agencies}
                        selector="name"
                        keys={['name']}
                        label="Agency name"
                        optionDefaultHeight={35}
                        value={agencyNameExcel}
                        onChange={(newValue: string) => setAgencyNameExcel(newValue)}
                        />
                    }
                    <div className="container">
                        <input type="file" onChange={handleFileChange} />
                        <h3>Drop your .csv, .xls file here</h3>
                        <h4>Follow below pattern</h4>
                        <h5>Name | Email | Age | RUT / Passport | Phone | Hotel | Nationality | Remark | Price | Tickets Price</h5>
                    </div>
                {
                    data.map((passenger, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <div className="input-wrap">
                                    <GeneralInput
                                    label="Name"
                                    value={passenger.name}
                                    name="name"
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    />
                                    <GeneralInput
                                    label="Email"
                                    value={passenger.email}
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    name="email"
                                    />
                                </div>
                                <div className="input-wrap">
                                    <GeneralInput
                                    label="Age"
                                    type="number"
                                    min={1}
                                    value={passenger.age}
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    name="age"
                                    />
                                    <GeneralInput
                                    label="RUT / Passport"
                                    value={passenger.rut_passport}
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    name="rut_passport"
                                    />
                                </div>
                                <div className="input-wrap">
                                    <GeneralInput
                                    label="Phone"
                                    value={passenger.phone}
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    name="phone"
                                    />
                                    <GeneralInput
                                    label="Hotel"
                                    value={passenger.hotel}
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    name="hotel"
                                    />
                                </div>
                                <div className="input-wrap">
                                    <GeneralInput
                                    label="Traspass Price"
                                    type="number"
                                    value={passenger.traspass_price}
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    name="traspass_price"
                                    />
                                    <GeneralInput
                                    label="Tickets Price"
                                    type="number"
                                    value={passenger.tickets}
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    name="tickets"
                                    />
                                </div>
                                <div className="input-wrap">
                                    <GeneralInput
                                    label="Remark"
                                    value={passenger.remark}
                                    onChange={e => onChange(e, passenger.__rowNum__)}
                                    name="remark"
                                    />
                                    <CountryInput
                                    value={passenger.nationality}
                                    onChange={(e) => onChange(e, passenger.__rowNum__)}
                                    label="Nationality"
                                    name="nationality"
                                    />
                                </div>
                            <hr />
                            </React.Fragment>
                        )
                    })
                }
                </div>
                {
                    data.length > 0 &&
                    <button type="submit"
                    onClick={addPassengers}
                    >
                        Create Passengers
                    </button>
                }
                <div className="close" title="close"
                    onClick={() => {
                        setRegisterPassengersTripId(undefined)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }

`

const Modal = styled.div`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
min-height: 400px;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
.title {
    font-size: 12px;
    color: #666;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
.container {
    height: 100px;
    z-index: 1;
    border-radius: 10px;
    border: 2px dashed ${props => props.theme.blueLight};
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3px;
    position: relative;
    h3 {
        font-size: 14px;
        color: #888;
        text-align: center;
    }
    h4 {
        font-size: 12px;
        color: #aaa;
        text-align: center;
    }
    h5 {
        font-size: 10px;
        color: #aaa;
        text-align: center;
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
.data {
    margin-top: 20px;
    .input-wrap {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
        > div {
            flex: 1;
        }
    }
    hr {
        margin-bottom: 20px;
    }
}
button[type=submit] {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button[type=submit]::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button[type=submit]:hover {
    color: ${props => props.theme.white};
}

button[type=submit]:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button[type=submit]:hover::before {
    top: -30px;
    left: -30%;
}
`

