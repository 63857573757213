import { CSSProperties, forwardRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { mobile } from "../../styles/responsive";

const ModalContainer = styled.div`
height: 100dvh;
width: 100vw;
position: fixed;
top: 0;
left: 0;
z-index: 100;
background-color: ${props => props.theme.blackTransparent};
.separator {
    margin-bottom: 20px;
}
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`
const showUp = keyframes`
    from {
        bottom: -100%;
    }
    to {
        bottom: 0;
    }
`


interface ModalInterface extends React.FormHTMLAttributes<HTMLFormElement> {
    containerStyle?: CSSProperties;
}



// Use forwardRef to forward the ref to the child component
const Modal = forwardRef<HTMLFormElement, ModalInterface>(
    ({ children, containerStyle, ...props }, ref) => {
        return (
            <ModalContainer style={containerStyle}>
                <ModalComponent ref={ref} {...props}>
                    {children}
                </ModalComponent>
            </ModalContainer>
        );
    }
);

export default Modal;

const ModalComponent = styled.form`
width: 70%;
max-width: 750px;
top: 50%;
left: 50%;
background-color: ${(props) => props.theme.white};
transform: translate(-50%, -50%);
position: absolute;
padding: 20px 25px;
animation: ${showUpModal} 0.3s ease forwards;
max-height: 85%;
overflow: auto;
box-shadow: 0 0 10px ${(props) => props.theme.blackTransparent};
${mobile(
    css`
    width: 100%;
    max-width: 870px;
    max-height: 90%;
    top: auto;
    border-radius: 15px 15px 0 0;
    transform: initial;
    left: auto;
    box-shadow: 0 -5px 10px ${(props) => props.theme.blackTransparent};
    animation: ${showUp} 0.3s ease forwards;
    `
)}

h1 {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 500;
}
h2 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 500;
}
h3 {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 500;
}
h4 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500;
}
.list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    > div {
        min-width: 200px;
        flex: 1;
    }
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
    color: ${props => props.theme.gray};
    cursor: pointer;
}
.input-container {
    display: flex;
    align-items: end;
    gap: 5px;
    margin-bottom: 25px;
    width: 100%;
    flex-wrap: wrap;
    > div {
        flex: 1;
    }
}
.actions {
    margin-bottom: 20px;
button {
 padding: 0.8em 1.8em;
 border: 2px solid ${props => props.theme.blue};
 position: relative;
 background-color: transparent;
 text-align: center;
 text-transform: uppercase;
 font-size: 16px;
 transition: .3s;
 cursor: pointer;
 border-radius: 10px;
 z-index: 1;
 font-family: inherit;
 color: ${props => props.theme.blue};
 float: right;
 overflow: hidden;
}

}
`