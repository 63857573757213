import styled, { keyframes } from "styled-components";
import { Message as MessageType } from "../../types/common.types";
import { useGlobal } from "../../context/global";


export default function Message ({
    type,
    content
}: MessageType) {
    const {setMessage} = useGlobal()
    return (
        <Main type={type}>
            {
                type === 'success' &&
                <div className="success">
                    <div className="success-checkmark">
                      <div className="check-icon">
                        <span className="icon-line line-tip"></span>
                        <span className="icon-line line-long"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                      </div>
                    </div>
                    <div className="success__title">{content}</div>
                    <div className="success__close"
                    onClick={() => setMessage(undefined)}
                    ><svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" height="20"><path fill="#393a37" d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z"></path></svg></div>
                </div>
            }
            {
                type === 'error' &&
                <div className="success">
                    <div className="error-container">
                        <div className="circle-border"></div>
                        <div className="circle">
                            <div className="error"></div>
                          </div>
                    </div>
                    <div className="success__title">{content}</div>
                    <div className="success__close"
                    onClick={() => setMessage(undefined)}
                    ><svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" height="20"><path fill="#393a37" d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z"></path></svg></div>
                </div>
            }
        </Main>
    )
}

const showup = keyframes`
from {
    top: -100%;
}
to {
    top: 30px;
}
`

const rotateCircle = keyframes`
0% {
    transform: rotate(-45deg);
}
5% {
    transform: rotate(-45deg);
}
12% {
    transform: rotate(-405deg);
}
100% {
    transform: rotate(-405deg);
}
`

const iconLineTip = keyframes`
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
`

const iconLineLong = keyframes`
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
`

const successAnim = keyframes`
0% {
  transform: scale(0);
}
30% {
  transform: scale(0);
}
100% {
  transform: scale(1);
}
`
  
const circleAnim = keyframes`
from {
  transform: scale(0);
}
to {
  transform: scale(1.1);
}
`

const Main = styled.div<{type: 'success' | 'error'}>`
position: fixed;
top: 30px;
left: 50%;
transform: translateX(-50%);
animation: ${showup} 0.3s ease forwards;
z-index: 100;
.success {
  width: 320px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background: ${props => props.theme.white};
  border-radius: 8px;
  box-shadow: 0px 0px 5px 3px ${props => props.theme.blackTransparent};
}

.success__title {
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.gray};
  max-height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.success__close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.success__close path {
  fill: ${props => props.theme.gray};
}
.success-checkmark {
    transform: scale(0.6);
    width: 80px;
    height: 80px;
    margin: 0 auto;
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: ${rotateCircle} 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: ${iconLineTip} 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: ${iconLineLong} 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

.error-container {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle,
.circle-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.circle {
  z-index: 1;
  position: relative;
  background: white;
  transform: scale(1);
  animation: ${successAnim} 700ms ease;
}

.circle-border {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  animation: ${circleAnim} 400ms ease;
  background: red;	
}

.error::before,
.error::after {
  content: "";
  display: block;
  height: 3px;
  background: red;
  position: absolute;
}

.error::before {
  width: 20px;
  top: 47%;
  left: 21px;
  transform: rotateZ(50deg);
}

.error::after {
  width: 20px;
  top: 47%;
  left: 21px;
  transform: rotateZ(-50deg);
}
`

