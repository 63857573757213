import styled, { keyframes } from "styled-components";

export default function Loader() {
    return (
        <Main>
            <svg viewBox="25 25 50 50">
                <circle r="20" cy="50" cx="50"></circle>
            </svg>
        </Main>
    )
}

const rotate4 = keyframes`
100% {
  transform: rotate(360deg) translate(-50%, -50%);
 }
`

const dash4 = keyframes`
0% {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
 }

 50% {
  stroke-dasharray: 90, 200;
  stroke-dashoffset: -35px;
 }

 100% {
  stroke-dashoffset: -125px;
 }
`

const Main = styled.div`
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
left: 0;
z-index: 200;
background-color: ${props => props.theme.blackTransparent};
svg {
 width: 3.25em;
 transform-origin: center;
 animation: ${rotate4} 2s linear infinite;
 position: absolute;
 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
}

circle {
 fill: none;
 stroke: hsl(214, 97%, 59%);
 stroke-width: 2;
 stroke-dasharray: 1, 200;
 stroke-dashoffset: 0;
 stroke-linecap: round;
 animation: ${dash4} 1.5s ease-in-out infinite;
}

`