import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PassengerService from '../../services/passenger.service'
import Details from '../../components/passengers/Details'
import { usePassenger } from '../../context/passenger'
import Plan from '../../components/passengers/Plan'
import { useNavigate } from 'react-router-dom'

export default function Home() {
    const {user, setUser} = usePassenger()
    const navigate = useNavigate()
    useEffect(() => {
        if (!user) return navigate('/access')
    }, [user])
    useEffect(() => {
        async function getInfo() {
            try {
                const response = (await PassengerService.me()).data
                setUser({...user, user: response})
            } catch (error) {
                console.log(error)
            }
        }
        getInfo()
    }, []) 
    return (
        <Main>
            <Details/>
            <Plan/>
        </Main>
    )
}

const Main = styled.div`
padding: 10dvh 0;
background-color: ${props => props.theme.whiteBone};
min-height: 100dvh;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 20px;
`