import styled from "styled-components"
import background from '../../assets/seller/background.jpeg'
import GeneralInput from "../../components/common/GeneralInput"
import { FormEvent, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'; 
import PassengerService from "../../services/passenger.service";
import { usePassenger } from "../../context/passenger";
import { useNavigate } from "react-router-dom";
import { mobile } from "../../styles/responsive";
import { useGlobal } from "../../context/global";

export default function Access() {
    const {setMessage} = useGlobal()
    const {user, setUser} = usePassenger()
    const location = useLocation();
    const router = useNavigate()
    const [passengerId, setPassengerId] = useState<string>('')
    const [isQueryDefined, setIsQueryDefined] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    useEffect(() => {
        if (user && user.user?.id === passengerId) router('/')
        console.log('loop')
    }, [router, user, isQueryDefined])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const queryParam = searchParams.get('passenger_id');
        if (!queryParam) return
        setIsQueryDefined(true)
        setPassengerId(queryParam)
    }, [location.search]);

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const response = (await PassengerService.login({id: passengerId, password})).data
            setUser(response)
            response?.token && PassengerService.setToken(response.token)
            localStorage.setItem('passenger', JSON.stringify(response))
        } catch (error: any) {
            if (!error.response.data) return
            setMessage({
                type: 'error',
                content: error.response.data
            })
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
        }
    }
    return (
        <Main>
            <Modal onSubmit={onSubmit}>
                <Left>
                    <h2>Welcome!</h2>
                    <h3>Log in to your account</h3>
                    <GeneralInput
                    style={{width: '80%'}}
                    value={passengerId}
                    label="Passenger ID"
                    disabled={isQueryDefined}
                    name="username"
                    required
                    inputStyle={{width: '100%'}}
                    onChange={e => setPassengerId(e.target.value)}
                    />
                    <GeneralInput
                    style={{width: '80%'}}
                    label="Password"
                    inputStyle={{width: '100%'}}
                    value={password}
                    required
                    name="password"
                    onChange={e => setPassword(e.target.value)}
                    />
                    <button 
                    type="submit"
                    >Sign in</button>
                </Left>
                <Right>
                    <div>
                        <img src={background} alt=""/>
                    </div>
                    <img src="/assets/logo.png" alt=""/>
                </Right>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
width: 100vw;
height: 100dvh;
display: flex;
align-items: center;
justify-content: center;
background-color: ${props => props.theme.whiteBone};
`

const Modal = styled.form`
height: 80%;
width: 80%;
max-width: 700px;
border-radius: 15px;
box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
overflow: hidden;
display: flex;
background-color: ${props => props.theme.white};
`

const Left = styled.div`
flex: 1;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;
h2 {
    font-size: 20px;
    font-weight: 400;
}
h3 {
    font-size: 14px;
    font-weight: 400;
}
> button {
    margin-top: 20px;
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

> button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

> button:hover {
    color: ${props => props.theme.white};
}

> button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

> button:hover::before {
    top: -30px;
    left: -30%;
}
`

const Right = styled.div`
${mobile({
    display: 'none'
})}
flex: 1;
height: 100%;
overflow: hidden;
position: relative;
div {
    height: 100%;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
> img {
    height: 30%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
`