import styled from "styled-components";
import { useSeller } from "../../context/seller";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SellerService from "../../services/seller.service";
import Profile from "../../components/sellers/Me/Profile";
import Sidebar from "../../components/sellers/Me/Sidebar";
import Info from "../../components/sellers/Me/Info";
import Health from "../../components/sellers/Me/Health";
import { mobile } from "../../styles/responsive";


export default function Me() {
    const {sellerInfo, setSellerInfo, sellerTab} = useSeller()
    const navigate = useNavigate()
    function back() {
        navigate('/seller')
    }
    useEffect(() => {
        async function me() {
            try {
                const response = await (await SellerService.me()).data
                setSellerInfo(response)
            } catch (error) {
                console.log(error)
            }
        }
        me()
    }, [])
    console.log(sellerInfo)
    return (
        <Main>
            <Navbar>
                <button onClick={back}>
                    <IoMdArrowRoundBack/>
                </button>
            </Navbar>
            <Left>
                <Profile/>
                <Sidebar/>
            </Left>
            <Right>
                {
                    sellerTab === 'profile' &&
                    <Info/>
                }
                {
                    sellerTab === 'health' &&
                    <Health/>
                }
            </Right>
        </Main>
    )
}

const Main = styled.div`
display: grid;
width: 80%;
margin: auto;
min-height: 100dvh;
padding-bottom: 5vh;
grid-template-columns: 1fr 1fr 1fr;
/* grid-template-rows: 70px 1fr 1fr; */
/* ${mobile({
    width: '90%'
})} */
`

const Navbar = styled.div`
grid-column: 1 / -1;
padding: 20px 0;
button {
    height: 30px;
    width: 30px;
    background-color: rgba(0,0,0,0);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
}
`

const Left = styled.div`
grid-column: 1 / span 1;
${mobile({
    gridColumn: '1 / -1',
    gridRow: '2 / span 1'
})}
`
const Right = styled.div`
grid-column: 2 / -1;
${mobile({
    gridColumn: '1 / -1',
    gridRow: '3 / span 1'
})}
`