import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components"
import Header from "./Header"
import List from "./List"
import AdminService from "../../../services/admin.service";
import { Traspass as TraspassType, AgencyTraspass, Payment } from "../../../types/common.types";
import { mobile } from "../../../styles/responsive";
import { useAdmin } from "../../../context/admin";

export default function Traspass() {
    const {user} = useAdmin()
    const [list, setList] = useState<TraspassType[]>([])
    const [payments, setPayments] = useState<Payment[]>([])
    useEffect(() => {
        async function getList() {
            try {
                const response = (await AdminService.listTraspass()).data
                setList(response.reservations)
                setPayments(response.payments)
            } catch (error) {
                console.log(error)
            }
        }
        getList()
    }, [])
    const balance_array_reservations = list.reduce((acc: AgencyTraspass[], curr: TraspassType) => {
        // Determine the amount to add to traspass_price based on balance
        const amount = curr.balance === 'positive' ? curr.traspass_price : -curr.traspass_price;
      
        // Find if there's already an entry for this agency in the accumulator
        const existingItem = acc.find(item => curr.balance === 'positive' ? curr.agency.id === item.agency.id : curr.agency_trip.id === item.agency.id );
    
        if (existingItem) {
            // If found, update the existing item's traspass_price
            existingItem.traspass_price += amount;
        } else {
            // If not found, create a new entry for the agency
            acc.push({
                agency: {
                    id: curr.balance === 'positive' ? curr.agency.id : curr.agency_trip.id,
                    email: curr.balance === 'positive' ? curr.agency.email : curr.agency_trip.email,
                    name: curr.balance === 'positive' ? curr.agency.name : curr.agency_trip.name
                },
                traspass_price: amount
            });
        }
      
        return acc;
    }, []);
    const balance_array = balance_array_reservations.map(obj => {
        const payments_array = payments.filter(payment => payment.owner === obj.agency.id || payment.agency_id === obj.agency.id)
        let amount = obj.traspass_price
        for (let i = 0; i < payments_array.length; i++) {
            const payment = payments_array[i];
            const type = payment.owner === user?.user.agency_id ? 'send' : 'receive'
            if (type === 'receive') {
                amount = amount - payment.amount
            } else {
                amount = amount + payment.amount
            }
        }
        return {...obj, traspass_price: amount}
    })
    const balance_positive = useMemo(() => {
        return balance_array.map(b => b.traspass_price > 0 ? b.traspass_price : 0).reduce((a, b) => a + b ,0)
    }, [balance_array])

    const balance_negative = useMemo(() => {
        return balance_array.map(b => b.traspass_price < 0 ? b.traspass_price : 0).reduce((a, b) => a + b ,0)
    }, [list])
    
    return (
        <Main>
            <Header
            positive={balance_positive}
            negative={balance_negative}
            />
            <List
            list={list}
            payments={payments}
            />
        </Main>
    )
}

const Main = styled.div`
width: 80%;
margin: auto;
${mobile({
    width: '100%'
})}
`