export default function jsonToFormData(
    data: any,
    formData: FormData = new FormData(),
    parentKey: string = ''
): FormData {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        if (Array.isArray(data)) {
            // Handle arrays
            data.forEach((value, index) => {
                const fullKey = parentKey ? `${parentKey}[${index}]` : index.toString();
                jsonToFormData(value, formData, fullKey);
            });
        } else {
            // Handle objects
            Object.entries(data).forEach(([key, value]) => {
                const fullKey = parentKey ? `${parentKey}[${key}]` : key;
                jsonToFormData(value, formData, fullKey);
            });
        }
    } else {
        // Handle primitive data (and Date/File as before)
        if (parentKey) {
            if (data instanceof File) {
                formData.append(parentKey, data);
            } else {
                formData.append(parentKey, data instanceof Date ? data.toISOString() : data);
            }
        }
    }
    return formData;
}