import React, { CSSProperties } from "react";
import styled from "styled-components";
import { IoIosClose } from "react-icons/io";

interface ComponentInterface {
    style?: CSSProperties;
    children: React.ReactNode;
    iconClose?: React.ReactNode;
    onClick?: () => any;
    close?: () => any;
}

export default function Component({
    style,
    children,
    close,
    iconClose,
    onClick,
}: ComponentInterface) {
    return (
        <Main
        style={style}
        onClick={onClick}
        iconClose={iconClose}
        >
            {children}
            { 
                close &&
                <div className="close" 
                onClick={close}
                >
                    {
                        iconClose || <IoIosClose/>
                    }
                </div>
            }
        </Main>
    )
}

const Main = styled.div<{onClick: any, iconClose: any}>`
border-radius: 10px;
background-color: ${props => props.theme.white};
box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
position: relative;
padding: 20px;
cursor: ${props => props.onClick ? 'pointer' : 'initial'};
.close {
    position: absolute;
    right: 20px;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    font-size: ${props => props.iconClose ? '16px' : '24px'};
    color: ${props => props.theme.gray};
    cursor: pointer;
}
p.small {
    font-size: 13px;
    color: ${props => props.theme.gray};
}
`