import './styles/App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components';
import Home from './pages/passengers/home';
import HomeAdmin from './pages/admin/home';
import HomeSeller from './pages/sellers/home';
import AccessSeller from './pages/sellers/access';
import theme from './styles/theme';
import { SellerContext } from './context/seller';
import Access from './pages/passengers/access';
import AccessAdmin from './pages/admin/access';
import { AdminContext } from './context/admin';
import Me from './pages/sellers/me';
import AddPassengers from './pages/admin/addPassengers';
import { PassengerContext } from './context/passenger';
import { useGlobal } from './context/global';
import Message from './components/common/Message';
import Loader from './components/common/Loader';
function App() {
  const {message, isLoading} = useGlobal()
  return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AdminContext>
          <SellerContext>
          <PassengerContext>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/access' element={<Access/>}/>
                  <Route path='/seller' element={<HomeSeller/>}/>
                  <Route path='/seller/access' element={<AccessSeller/>}/>
                  <Route path='/seller/me' element={<Me/>}/>
                  <Route path='/admin' element={<HomeAdmin/>}/>
                  <Route path='/admin/access' element={<AccessAdmin/>}/>
                <Route path='/admin/addPassengers' element={<AddPassengers/>}/>
            </Routes>
            {
              message &&
              <Message
              content={message.content}
              type={message.type}
              />
            }
            {
              isLoading &&
              <Loader/>
            }
          </PassengerContext>
          </SellerContext>
          </AdminContext>
        </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;