import styled from "styled-components";
import * as CommonTypes from "../../../types/common.types"
import { countries } from "countries-list";
import {
    format, 
} from "date-fns";
import { MdMonetizationOn } from "react-icons/md";
import { IoPersonAddOutline } from "react-icons/io5";
import { FaList } from "react-icons/fa";
import { useAdmin } from "../../../context/admin";
import AdminService from "../../../services/admin.service";
import insert from "../../../utils/insert";
import removeTimezone from "../../../utils/removeTimezone";
import { mobile } from "../../../styles/responsive";

interface ListInterface {
    list: CommonTypes.TripReservation[]
    setList: Function
}

export default function List({
    list,
    setList
}: ListInterface) {
    const { setRegisterPassengersTripId, setRegisterCostsTripId, setRequirementsTripId, user } = useAdmin()
    async function updatePassStatus(reservation_id: string, trip_id: string) {
        try {
            const trip = list.find(item => item.id === trip_id)
            if (!trip) return 
            const tripIndex = list.indexOf(trip)
            const reservation = trip.reservations.find(item => item.id === reservation_id)
            if (!reservation) return
            const reservationIndex = trip.reservations.indexOf(reservation)
            const newReservationInfo = {...reservation, pass_bought: !reservation.pass_bought}
            const filteredReservationsList = trip.reservations.filter(item => item.id !== reservation_id)
            const newReservationsList = insert(filteredReservationsList, reservationIndex, newReservationInfo)
            const filteredTripsList = list.filter(item => item.id !== trip_id)
            const newTrip = {...trip, reservations: newReservationsList}
            const newTripsList = insert(filteredTripsList, tripIndex, newTrip)
            setList(newTripsList)
            await AdminService.updatePassStatus(!reservation.pass_bought, reservation_id)
        } catch (error) {
            console.log(error)
        }
    }
    async function updateArriveStatus(reservation_id: string, trip_id: string) {
        try {
            const trip = list.find(item => item.id === trip_id)
            if (!trip) return 
            const tripIndex = list.indexOf(trip)
            const reservation = trip.reservations.find(item => item.id === reservation_id)
            if (!reservation) return
            const reservationIndex = trip.reservations.indexOf(reservation)
            const newReservationInfo = {...reservation, arrived_agency: !reservation.arrived_agency}
            const filteredReservationsList = trip.reservations.filter(item => item.id !== reservation_id)
            const newReservationsList = insert(filteredReservationsList, reservationIndex, newReservationInfo)
            const filteredTripsList = list.filter(item => item.id !== trip_id)
            const newTrip = {...trip, reservations: newReservationsList}
            const newTripsList = insert(filteredTripsList, tripIndex, newTrip)
            setList(newTripsList)
            await AdminService.updateArriveStatus(!reservation.arrived_agency, reservation_id)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Main>
            {
                list.map((trip, idx) => {
                    if (!trip.trip_date) return <></>
                    const date = removeTimezone(trip.trip_date.toString())
                    console.log(date)
                    return (
                        <div className="tour" key={idx}>
                            <div className="header">
                                <div className="name">
                                    <span>
                                        {trip.tour_id}
                                    </span>
                                    <span>
                                        {trip.trip_date && date}
                                    </span>
                                    <span>
                                        {trip.trip_time}
                                    </span>
                                </div>
                                <div className="actions">
                                    <button className="request"
                                    title="Request Passengers"
                                    onClick={() => {
                                        setRegisterPassengersTripId(trip.id)
                                    }}
                                    >
                                        <IoPersonAddOutline/>
                                    </button>
                                    <button className="costs"
                                    title="Register costs"
                                    onClick={() => {
                                        setRegisterCostsTripId(trip.id)
                                    }}
                                    >    
                                        <MdMonetizationOn/>
                                    </button>
                                    <button className="requirements"
                                    title="Requirements"
                                    onClick={() => {
                                        setRequirementsTripId(trip.id)
                                    }}
                                    >
                                        <FaList/>
                                    </button>
                                </div>
                            </div>
                            <TableWrapper>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th
                                            style={{width: '40px'}}
                                            ></th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th style={{width: '70px'}}>Age</th>
                                            <th>Born</th>
                                            <th>Traspassed From</th>
                                            <th>Price</th>
                                            <th style={{width: '70px'}}>Pass Bought</th>
                                            <th style={{width: '70px'}}>Arrived</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            trip.reservations?.map((reservation, idx) => {
                                            return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{reservation.passenger?.name}</td>
                                                        <td><a href={`mailto:${reservation.passenger?.email}`}>{reservation.passenger?.email}</a></td>
                                                        <td>{reservation.passenger?.age} y/o</td>
                                                        <td>{countries[reservation.passenger?.nationality as keyof typeof countries]?.name}</td>
                                                        <td>{reservation.passenger?.traspassed_from || (user?.user.agency_id !== reservation.agency.id && reservation.agency.name)}</td>
                                                        <td>{reservation.traspass_price || reservation.price}</td>
                                                        <td><button
                                                        className={reservation.pass_bought ? 'selected' : ''}
                                                        onClick={() => updatePassStatus(reservation.id, trip.id)}></button></td>
                                                        <td><button
                                                        className={reservation.arrived_agency ? 'selected' : ''}
                                                        onClick={() => updateArriveStatus(reservation.id, trip.id)}
                                                        ></button></td>
                                                        {/* <td><button onClick={() => setTripInfo(reservation)}><AiOutlineInfoCircle/></button></td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </div>
                    )
                })
            }
        </Main>
    )
}

const Main = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
width: 90%;
margin: auto;
margin-top: 15px;
max-height: calc(65vh - 90px);
${mobile({
    width: '100%'
})}
overflow: unset;
.tour {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    .header {
        ${mobile({
            padding: '5px 0',
            height: '70px',
            flexDirection: 'column'
        })}
        .name {
            flex: 1;
            text-align: center;
            display: flex;
            gap: 10px;
            justify-content: center;
        }
        .actions {
            height: 100%;
            display: flex;
            gap: 5px;
            padding: 5px;
            button {
                height: 100%;
                width: 50px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                color: ${props => props.theme.white};
            }
            button.request {
                background-color: ${props => props.theme.blue};
            }
            button.costs {
                background-color: ${props => props.theme.lightGreen};
            }
            button.requirements {
                background-color: ${props => props.theme.yellowDark};
            }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
        
    }
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
overflow-x: unset;
`

const Table = styled.table`
border-collapse: collapse;
table-layout: fixed;
height: 100%;
position: relative;
width: 100%;
min-width: 500px;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .flag {
        width: 30px;
    }
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
        }
    }
    > button.selected {
        background-color: ${props => props.theme.blueLight};
    }
}
tr {
    height: 40px;
    width: 100%;
}
`