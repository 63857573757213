import styled, {keyframes} from "styled-components";
import { IoCaretBackCircleOutline } from "react-icons/io5"
import { useSeller } from "../../../context/seller";
import GeneralInput from "../../common/GeneralInput";
import dollar from '../../../assets/common/dollar.svg'
import { ChangeEvent } from "react";
import Dropdown from "../../common/Dropdown";
import * as CommonTypes from '../../../types/common.types'
import insert from "../../../utils/insert";
import { FaTrash } from "react-icons/fa";
import SellerService from "../../../services/seller.service";
import { useGlobal } from "../../../context/global";

export default function TicketPayment() {
    const {setMessage} = useGlobal()
    const {newReservationData, setNewReservationData, newPassenger, setIsTicketPaymentModalOpen} = useSeller()
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const name = e.target.name
        setNewReservationData({...newReservationData, [name]: value})
    }
    function onSelectPayment(newMethod: string) {
        const new_data = newReservationData.payment_methods ? [...newReservationData.payment_methods, {name: newMethod}] : [{name: newMethod}]
        setNewReservationData({...newReservationData, payment_methods: new_data})
    }
    function onChangePayment(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const name = e.target.name
        const oldItem = newReservationData.payment_methods.find((item: CommonTypes.PaymentMethods) => item.name === name)
        const index = newReservationData.payment_methods.indexOf(oldItem)
        console.log(index)
        const filteredList = [...newReservationData.payment_methods.filter((item: CommonTypes.PaymentMethods) => item.name !== name)]
        const newData = {...oldItem, value}
        setNewReservationData({...newReservationData, payment_methods: insert(filteredList, index, newData)})
    }

    function deletePayment(name: string) {
        const newList = newReservationData.payment_methods.filter((item: CommonTypes.PaymentMethods) => item.name !== name)
        setNewReservationData({...newReservationData, payment_methods: newList})
    } 

    async function onSubmit() {
        try {
            await SellerService.createReservation({passenger_id: newPassenger.id , ...newReservationData})
            setIsTicketPaymentModalOpen(false)
            setNewReservationData()
            setMessage({
                type: 'success',
                content: 'Passenger successfully assigned to tour'
            })
            setTimeout(() => setMessage(undefined), 2500)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main>
            <Modal>
                <button className="back"
                onClick={() => setIsTicketPaymentModalOpen(false)}
                ><IoCaretBackCircleOutline/></button>
                <h1>Payment</h1>
                <GeneralInput
                label="Ticket Price*"
                placeholder="$20000"
                value={newReservationData.tickets || ''}
                name="tickets"
                type="number"
                onChange={onChange}
                image={dollar}
                
                />
                <GeneralInput
                style={{marginTop: '20px'}}
                label="Sale Price"
                placeholder="30000"
                value={newReservationData.price || ''}
                type="number"
                name="price"
                image={dollar}
                onChange={onChange}
                />
                <h3 className="payment-title">Payment methods</h3>
                {
                    newReservationData?.payment_methods?.map((method: CommonTypes.PaymentMethods, idx: number) => {
                        return (
                            <div className="payment_method" key={idx}>
                                <button
                                onClick={() => deletePayment(method.name)}
                                >
                                    <FaTrash/>
                                </button>
                                <GeneralInput
                                value={method.value || ''}
                                name={method.name}
                                type="number"
                                placeholder="Amount"
                                label={method.name}
                                image={dollar}
                                onChange={onChangePayment}
                                />
                            </div>
                        )
                    })
                }
                <Dropdown
                style={{marginTop: '20px'}}
                options={['efectivo', 'wise', 'transferencia', 'tarjeta', 'deuda'].filter(str => !newReservationData?.payment_methods?.map((item: CommonTypes.PaymentMethods) => item.name).includes(str))}
                title="Payment methods"
                onSelect={onSelectPayment}
                />
                <button className="continue"
                onClick={onSubmit}
                >Confirm Payment</button>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`
const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`


const Modal = styled.div`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
h3 {
    margin: 0;
    margin-top: 10px;
}
h3.payment-title {
    font-weight: 500;
    margin-bottom: 10px;
}
.payment_method {
    margin-bottom: 10px;
    position: relative;
    button {
        position: absolute;
        right: 0;
        top: 0;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background-color: rgba(0,0,0,0);
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid ${props => props.theme.dark};
    }
    h4 {
        font-weight: 400;
        margin-bottom: 5px;
    }
    label {
        text-transform: capitalize;
    }
}
> button.back {
    background-color: rgba(0,0,0,0);
    border: none;
    font-size: 20px;
    cursor: pointer;
    transition: transform .3s ease;
    &:hover {
        transform: translateY(-3px);
    }
}
> button.continue {
    margin-top: 20px;
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

> button.continue::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

> button.continue:hover {
    color: ${props => props.theme.white};
}

> button.continue:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

> button.continue:hover::before {
    top: -30px;
    left: -30%;
}
`