import React, { useContext, useState } from "react";
import { Message } from "../types/common.types";
import { GlobalContextType } from "../types/global";

const AppProvider = React.createContext<GlobalContextType>(null as any)

export const AppContext = ({children}: {children: React.ReactNode}) => {
    const [message, setMessage] = useState<Message>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const values = {
        message, setMessage,
        isLoading, setIsLoading,
    }
    return <AppProvider.Provider value={values}>{children}</AppProvider.Provider>;
}

export const useGlobal = () => {
    return useContext(AppProvider);
};