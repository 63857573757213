import { CSSProperties } from "react";
import styled from "styled-components";

interface CheckboxInterface {
    color?: string,
    onChange?: Function,
    containerStyle?: CSSProperties,
    inputStyle?: CSSProperties,
    checked?: boolean,
    label?: string
}

export default function Checkbox({
    color,
    onChange,
    inputStyle,
    containerStyle,
    checked,
    label
}: CheckboxInterface) {
    return (
        <Main color={color} style={containerStyle}>
        <input checked={checked} 
        style={inputStyle}
        onChange={e => {
          onChange && onChange(e.target.checked)
        }}
        type="checkbox" className="cyberpunk-checkbox"/>
        {label}</Main>
    )
}


const Main = styled.label<{color?: string}>`
.cyberpunk-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${props => props.color || '#30cfd0'};
  border-radius: 5px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.cyberpunk-checkbox:before {
  content: "";
  background-color: ${props => props.color || '#30cfd0'};
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 10px;
  height: 10px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.cyberpunk-checkbox:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

  font-size: 16px;
  color: #000;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`