import styled, { keyframes } from 'styled-components'
import { useAdmin } from '../../../context/admin'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FullReservation } from '../../../types/common.types'
import { countries } from "countries-list";
import AdminService from '../../../services/admin.service';
import { useGlobal } from '../../../context/global';
import { FaTrash } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";

export default function ReservationsDetails() {
    const {setMessage} = useGlobal()
    const { reservationDetails, setEditReservationId, setReservationDetails, setPassengerPlanId} = useAdmin()
    async function deleteReservation(id: string) {
        try {
            if (!reservationDetails) return
            // await AdminService.deleteReservation(id)
            const newReservations = reservationDetails.filter((obj: FullReservation) => obj.id !== id )
            setReservationDetails(newReservations)
            setMessage({
                type: 'success',
                content: 'Reservation successfully deleted'
            })
            setTimeout(() => setMessage(undefined), 2500)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main>
            <Modal>
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{width: '40px'}}>
                                    
                                </th>
                                <th>Nombre</th>
                                <th>Nacionalidad</th>
                                <th>RUT / Passport</th>
                                <th>Hotel</th>
                                <th>Contacto</th>
                                <th>Edad</th>
                                <th>Observaciones</th>
                                <th>Precio</th>
                                <th>Ticket</th>
                                <th>Vendedor</th>
                                <th style={{width: '40px'}}
                                ></th>
                                <th style={{width: '40px'}}
                                ></th>
                                <th style={{width: '40px'}}
                                ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reservationDetails?.map((reservation, idx:  number) => {
                                return (
                                        <tr key={idx}>
                                            <td>
                                                {idx + 1}
                                            </td>
                                            <td>{reservation.passenger.name}</td>
                                            <td>{countries[reservation.passenger.nationality as keyof typeof countries].name}</td>
                                            <td>{reservation.passenger.rut_passport}</td>
                                            <td>{reservation.passenger.hotel}</td>
                                            <td>{reservation.passenger.phone}</td>
                                            <td>{reservation.passenger.age} y/o</td>
                                            <td>{reservation.passenger.remark}</td>
                                            <td>{reservation.price}</td>
                                            <td>{reservation.tickets}</td>
                                            <td>{reservation.passenger.seller?.name}</td>
                                            <td><button className="info" onClick={() => setPassengerPlanId(reservation.passenger_id)}><AiOutlineInfoCircle/></button></td>
                                            <td><button className="info" onClick={() => setEditReservationId(reservation.id)}><MdEdit/></button></td>
                                            <td><button className="info" onClick={() => deleteReservation(reservation.id)}><FaTrash/></button></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </TableWrapper>
                <div className="close" title="close"
                    onClick={() => {
                        setReservationDetails(undefined)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 10;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.div`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 1000px;
position: absolute;
top: 50%; 
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-right: 50px;
animation: ${showUpModal} .3s ease forwards;
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: auto;
`

const Table = styled.table`
border-collapse: collapse;
position: relative;
table-layout: fixed;
height: 100%;
width: 100%;
min-width: 1000px;
thead {
    width: 100%;
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.white};
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
            }
    }
    >button.selected-all {
        background-color: ${props => props.theme.yellowDark};
    }
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    button {
        background-color: #FFF;
    }
    button.selected {
        background-color: ${props => props.theme.yellowDark};
    }
    button.info {
        border: none;
        background-color: none;
        font-size: 16px;
        cursor: pointer;
    }
}
tr {
    height: 40px;
    width: 100%;
}
`