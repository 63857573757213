import styled, {keyframes} from "styled-components";
import { useAdmin } from "../../../context/admin";
import { useEffect, useState } from "react";
import AdminService from "../../../services/admin.service";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import * as CommonTypes from '../../../types/common.types'
import { format } from "date-fns";
import removeTimezone from "../../../utils/removeTimezone";

export default function PassengerPlan() {
    const {passengerPlanId, setPassengerPlanId} = useAdmin()
    const [plan, setPlan] = useState<CommonTypes.PassengerPlan[]>([])
    useEffect(() => {
        async function getPlan() {
            if (!passengerPlanId) return
            try {
                const response = (await AdminService.getPassengerPlan(passengerPlanId)).data
                setPlan(response)
            } catch (error) {
                console.log(error)
            }
        }
        getPlan()
    }, [])
    console.log(plan)
    return (
        <Main>
            <Modal>
                {
                    plan.map((item, idx) => {
                        return (
                            <div key={idx} className="plan">
                                <div className="tour">
                                    <h1>{item.tour_id}</h1>
                                </div>
                                <div className="info">

                                <h2>Reservation</h2>
                                <h3>Reservation ID: <span>{item.reservation_id}</span></h3>
                                <h3>Reservation Date: <span>{removeTimezone(item.reservation_date.toString())}</span></h3>
                                <h3>Tickets Qty: <span>{item.tickets}</span></h3>

                                <h2>Agency: <span>{item.agency_name}</span></h2>
                                <h3>Agency Id: <span>{item.agency_id}</span></h3>

                                {
                                    item.trip_id &&
                                    <>
                                    <h2>Trip</h2>
                                    <h3>Trip ID: <span>{item.trip_id}</span></h3>
                                    <h3>Trip agency: <span>{item.trip_agency_name}</span></h3>
                                    <h3>Trip agency ID: <span>{item.trip_agency_id}</span></h3>
                                    <h3>Trip date: {item.trip_date && removeTimezone(item.trip_date.toString())}</h3>
                                    </>
                                }
                                </div>
                            </div>
                        )
                    })
                }
                <div className="close" title="close"
                    onClick={() => setPassengerPlanId(undefined)}
                    >
                        <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.div`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
.plan {
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.gray};
    .tour {
        background-color: ${props => props.theme.blue};
        border-radius: 10px 10px 0 0;
        color: ${props => props.theme.white};
        padding: 10px 0;
        h1 {
            text-align: center;
            font-size: 20px;
            margin: 0;
            font-weight: 600;
        }
    }
    .info {
        padding: 10px;
    }
    h2 {
        font-size: 18px;
        margin-top: 20px;
        font-weight: 500;
        margin: 5px 10px;
        &:first-child {
            margin: 0 10px;
            margin-bottom: 5px;
        }
    }
    h3 {
        font-size: 14px;
        margin: 0 10px;
        font-weight: 400;
    }
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
button {
    display: inline-block;
    width: 6.5em;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: 170px;
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button:hover {
    color: ${props => props.theme.white};
}

button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button:hover::before {
    top: -30px;
    left: -30px;
}
`