import styled from "styled-components";
import { HealthSeller, Sale } from "../../../types/common.types";
import { mobile } from "../../../styles/responsive";
import removeTimezone from "../../../utils/removeTimezone";
import formatNumberToString from "../../../utils/formatNumber";
import { LuSave } from "react-icons/lu";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import AdminService from "../../../services/admin.service";
import { AiFillDelete } from "react-icons/ai";
import { useGlobal } from "../../../context/global";
import insert from "../../../utils/insert";

function SaleComponent({sale, deleteSale, updateSale}: {sale: Sale, deleteSale: Function, updateSale: Function}) {
    const {setMessage} = useGlobal()
    const commission = useMemo(() => {
       return (sale.reservation.price * sale.seller.sell_commission) / 100
    }, [sale]) 
    const date = removeTimezone(sale.reservation.created_at.toString())
    const costs = ((sale.reservation.trip.costs?.map(obj => obj.value).reduce((a, b) => a + b, 0)) / sale.reservation.trip.reservations || 0) + (sale.reservation.cost || 0)
    async function save() {
        try {
            const response = await AdminService.updateSales(sale.authorized, Number(sale.amount || commission), sale.id)
            setMessage({type: 'success', content: response.data})
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="tour">
            <div className="header">
                <div className="name">
                    <span>
                        {sale.reservation.passenger.name} {sale.reservation.tour_id}
                    </span>
                </div>
                <div className="actions">
                    <button className="save" onClick={save}><LuSave/></button>
                    <button className="delete" onClick={() => deleteSale(sale.id)}><AiFillDelete/></button>
                </div>
            </div>
            <TableWrapper> 
                <Table>
                    <thead>
                        <tr>
                            <th>Fecha de venta</th>
                            <th>Precio de venta</th>
                            <th>Precio de traspaso</th>
                            <th>Forma de pago</th>
                            <th>Tickets</th>
                            <th>Traspass price</th>
                            <th>Operation cost</th>
                            <th>Ganancia agencia</th>
                            <th>% comision</th>
                            <th>comision</th>
                            <th>auth</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{date}</td>
                            <td>{formatNumberToString(sale.reservation.price)}</td>
                            <td>{formatNumberToString(sale.reservation.traspass_price)}</td>
                            <td>{sale.reservation.payment_methods.map((obj, idx) => ` ${idx > 0 ? '-' : ''} ${obj.name}`)}</td>
                            <td>{sale.reservation.tickets}</td>
                            <td>{formatNumberToString(sale.reservation.traspass_price || 0)}</td>
                            <td>{formatNumberToString(costs || 0)}</td>
                            <td>{formatNumberToString(sale.reservation.traspass_price ? sale.reservation.price - sale.reservation.traspass_price - commission : sale.reservation.price - costs - commission)}</td>
                            <td>{sale.amount ? `${formatNumberToString((sale.amount * 100) / sale.reservation.price)}%` : `${formatNumberToString(sale.seller.sell_commission)}%`}</td>
                            <td>
                                <input value={sale.amount || commission} type="number" onChange={e => updateSale(sale.id, 'amount', Number(e.target.value))} />
                            </td>
                            <td><button onClick={() => updateSale(sale.id, 'authorized', !sale.authorized)} className={sale.authorized ? 'selected' : ''}></button></td>
                        </tr>
                    </tbody>
                </Table>
            </TableWrapper>
        </div>
    )
}

export default function Sales({sales, setData}: {sales: Sale[], setData: Dispatch<SetStateAction<HealthSeller>>}) {
    async function deleteSale(id: string) {
        try {
            const salesFiltered = sales.filter(sale => sale.id !== id)
            setData(prevData => ({...prevData, sales: salesFiltered}))
            await AdminService.deleteSale(id)
        } catch (error) {
            console.log(error)
        }
    }
    async function updateSale(id: string, key: keyof Sale, value: any) {
        const oldItem = sales.find(sale => sale.id === id)
        if (!oldItem) return
        const index = sales.indexOf(oldItem)
        const salesFiltered = sales.filter(sale => sale.id !== id)
        const newItem = {...oldItem, [key]: value}
        const newSales = insert(salesFiltered, index, newItem)
        setData(prevData => ({...prevData, sales: newSales as Sale[]}))
    }
    return (
        <Main>
            {sales?.map((sale, idx) => {
                return <SaleComponent deleteSale={deleteSale} updateSale={updateSale} key={idx} sale={sale}/>
            })}
        </Main>
    )
}

const Main = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
width: 90%;
margin: auto;
margin-top: 15px;
${mobile({
    width: '100%'
})}
overflow: unset;
.tour {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    .header {
        ${mobile({
            padding: '5px 0',
            height: '70px',
            flexDirection: 'column'
        })}
        .name {
            flex: 1;
            text-align: center;
        }
        .actions {
            height: 100%;
            display: flex;
            gap: 5px;
            padding: 5px;
            button {
                height: 100%;
                width: 50px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                color: ${props => props.theme.white};
            }
            button.save {
                background-color: ${props => props.theme.yellowDark};
            }
            button.delete {
                background-color: ${props => props.theme.lightRed};
            }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
    }
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
overflow-x: unset;
`

const Table = styled.table`
border-collapse: collapse;
position: relative;
table-layout: fixed;
height: 100%;
width: 100%;
min-width: 850px;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
            }
    }
    >button.selected-all {
        background-color: ${props => props.theme.yellowDark};
    }
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .flag {
        width: 30px;
    }
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
        }
    }
    > button.selected {
        background-color: ${props => props.theme.blueLight};
    }
    input {
        width: 100%;
        height: 100%;
        text-align: center;
        border: none;
        background-color: rgba(0,0,0,0);
    }
}
tr {
    height: 40px;
    width: 100%;
}
`