import { StyledObject, css } from "styled-components"

export const mobile = (styles: any) => css`
    @media only screen and (max-width: 870px) {
        ${styles}
    }
`;

export const tiny = (props: StyledObject<Object>) => {
    return css`
    @media only screen and (max-width: 400px) {
        ${props}
    }
    `
}