import styled, {keyframes} from "styled-components";
import { useAdmin } from "../../../context/admin";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import AdminService from "../../../services/admin.service";
import * as CommonTypes from '../../../types/common.types'
import { LuSave } from "react-icons/lu";
import removeTimezone from "../../../utils/removeTimezone";
import { useGlobal } from "../../../context/global";

interface SaleInterface {
    sale: CommonTypes.Sale
}

function Sale({sale}: SaleInterface) {
    const {setMessage} = useGlobal()
    const costs = (sale.reservation.trip.costs?.map(obj => obj.value).reduce((a, b) => a + b, 0) || 0) + (sale.reservation.cost || 0)
    const commission = sale.amount || ((sale.reservation.price * sale.seller.sell_commission) / 100)
    const [sellerCommission, setSellerCommission] = useState<number>(sale.amount || commission)
    const [auth, setAuth] = useState<boolean>(sale.authorized)

    async function save() {
        try {
            const response = await AdminService.updateSales(auth, sellerCommission, sale.id)
            setMessage({type: 'success', content: response.data})
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
        } catch (error) {
            console.log(error)
        }
    }
    if (!sale.reservation.trip.trip_date) return <></>
    const date = removeTimezone(sale.reservation.trip.trip_date.toString())
    return (
        <div className="sale">
            <div className="header">
                <div className="name">
                    <span>
                        {sale.seller.name}
                    </span>
                    <span>
                        {sale.reservation.tour_id}
                    </span>
                    <span>{sale.reservation.trip.trip_date && date}</span>
                    <span>{sale.reservation.trip.trip_time}</span>
                </div>
                <div className="actions">
                    <button className="save" onClick={save}><LuSave/></button>
                </div>
            </div>
            <TableWrapper>
                <Table>
                    <thead>
                        <tr>
                            <th>Agency Trip</th>
                            <th>Passenger</th>
                            <th>Ticket Price</th>
                            <th>Sale Price</th>
                            <th>Traspass Price</th>
                            <th>Costs</th>
                            <th>{sale.seller.sell_commission || 0}%</th>
                            <th style={{width: '45px'}}>Auth</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{sale.reservation.trip.agency.name}</td>
                            <td>{sale.reservation.passenger.name}</td>
                            <td>{sale.reservation.tickets}</td>
                            <td>{sale.reservation.price}</td>
                            <td>{sale.reservation.traspass_price}</td>
                            <td>{!sale.reservation.traspass_price && costs}</td>
                            <td><input value={sellerCommission} type="number" onChange={e => setSellerCommission(Number(e.target.value))} /></td>
                            <td><button onClick={() => setAuth(!auth)} className={auth ? 'selected' : ''}></button></td>
                        </tr>
                    </tbody>
                </Table>
            </TableWrapper>
        </div>
    )
}

export default function Accounting() {
    const {setAccountingSellerId, accountingSellerId} = useAdmin()
    const [sales, setSales] = useState<CommonTypes.Sale[]>([])
    useEffect(() => {
        async function getSales() {
            try {
                if (!accountingSellerId) return 
                const response = await (await AdminService.listSales(accountingSellerId)).data
                setSales(response)
            } catch (error) {
                console.log(error)
            }
        }
        getSales()
    }, [])
    return (
        <Main>
            <Modal>
                {
                    sales.map((sale, idx) => {
                        return <Sale sale={sale} key={idx} />
                    })
                }
                {
                    sales.length === 0 &&
                    <>No sales</>
                }
                <div className="close" title="close"
                    onClick={() => {
                        setAccountingSellerId(undefined)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.div`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 700px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
.sale {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    margin-bottom: 20px;
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        gap: 10px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
        .name {
            flex: 1;
            text-align: center;
            display: flex;
            gap: 10px;
            justify-content: center;
        }
        .actions {
            height: 100%;
            display: flex;
            gap: 5px;
            padding: 5px;
            button {
                height: 100%;
                width: 50px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                color: ${props => props.theme.white};
            }
            button.save {
                background-color: ${props => props.theme.yellowDark};
            }
        }
    }
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
button[type=submit] {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button[type=submit]::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button[type=submit]:hover {
    color: ${props => props.theme.white};
}

button[type=submit]:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button[type=submit]:hover::before {
    top: -30px;
    left: -30%;
}
`

const TableWrapper = styled.div`

`

const Table = styled.table`
table-layout: fixed;
height: 100%;
width: 100%;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .flag {
        width: 30px;
    }
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
        }
    }
    > button.selected {
        background-color: ${props => props.theme.blueLight};
    }
    input {
        width: 100%;
        height: 100%;
        text-align: center;
        border: none;
        background-color: rgba(0,0,0,0);
    }
}
tr {
    height: 40px;
    width: 100%;
}
`