import React, {useContext, useState} from "react";
import { Admin, Agency, Driver, FullReservation, GlobalUser, Guider, Passenger, Payment, Reservation, Seller, Tour } from "../types/common.types";
import AdminService from "../services/admin.service";
import { startOfToday } from 'date-fns'
import { AdminContextType } from "../types/admin";

const AdminProvider = React.createContext<AdminContextType>(null as any)

export const AdminContext = ({children}: {children: React.ReactNode}) => {
    const getUser = () => {
        const user = localStorage.getItem('admin')
        if (user) {
            return JSON.parse(user)
        } else return null
    }
    // useStates
    const [user, setUser] = useState<GlobalUser>(getUser())
    const [tab, setTab] = useState<string>()
    const [isCreateTourModalOpen, setIsCreateTourModalOpen] = useState(false)
    const [tourInfoId, setTourInfoId] = useState<string>()
    const [isCreatePassengerModalOpen, setIsCreatePassengerModalOpen] = useState(false)
    const [isCreateAgencyModalOpen, setIsCreateAgencyModalOpen] = useState(false)
    const [isCreateSellerModalOpen, setIsCreateSellerModalOpen] = useState(false)
    const [isCreateAdminModalOpen, setIsCreateAdminModalOpen] = useState(false)
    const [isCreateDriverModalOpen, setIsCreateDriverModalOpen] = useState(false)
    const [isCreateGuiderModalOpen, setIsCreateGuiderModalOpen] = useState(false)
    const [passengerPlanId, setPassengerPlanId] = useState<string>()
    const [assignTripReservationId, setAssignTripReservationId] = useState<string[]>([])
    const [assignTripTourId, setAssignTripTourId] = useState<string>()
    const [traspassTripReservationId, setTraspassTripReservationId] = useState<string[]>([])
    const [traspassTripTourId, setTraspassTripTourId] = useState<string>()
    const [registerPassengersTripId, setRegisterPassengersTripId] = useState<string>()
    const [registerCostsTripId, setRegisterCostsTripId] = useState<string>()
    const [requirementsTripId, setRequirementsTripId] = useState<string>()
    const [accountingSellerId, setAccountingSellerId] = useState<string>()
    const [editTripId, setEditTripId] = useState<string>()
    const [relationshipAgencyId, setRelationshipAgencyId] = useState<string>()
    const [editReservationId, setEditReservationId] = useState<string>()
    const [editPassengerData, setEditPassengerData] = useState<Partial<Passenger>>()
    const [editTourData, setEditTourData] = useState<Partial<Tour>>()
    const [editDriverData, setEditDriverData] = useState<Partial<Driver>>()
    const [editGuiderData, setEditGuiderData] = useState<Partial<Guider>>()
    const [editSellerData, setEditSellerData] = useState<Partial<Seller>>()
    const [editAdminData, setEditAdminData] = useState<Partial<Admin>>()
    const [editAgencyData, setEditAgencyData] = useState<Partial<Agency>>()
    const [addPaymentData, setAddPaymentData] = useState<Partial<Payment>>()
    const [reservationDetails, setReservationDetails] = useState<FullReservation[]>()
    const [viewPaymentAgencyId, setViewPaymentAgencyId] = useState<string>()
    const [editTourInfoId, setEditTourInfoId] = useState<string>()

    const [currentDay, setCurrentDay] = useState<Date>(startOfToday())
    const [endDay, setEndDay] = useState<Date>()
    // add token to class object
    user?.token && AdminService.setToken(user.token)

    return <AdminProvider.Provider value={
        {
            user, setUser,
            tab, setTab,
            isCreateTourModalOpen, setIsCreateTourModalOpen,
            tourInfoId, setTourInfoId,
            isCreatePassengerModalOpen, setIsCreatePassengerModalOpen,
            isCreateAgencyModalOpen, setIsCreateAgencyModalOpen,
            isCreateSellerModalOpen, setIsCreateSellerModalOpen,
            isCreateAdminModalOpen, setIsCreateAdminModalOpen,
            isCreateDriverModalOpen, setIsCreateDriverModalOpen,
            isCreateGuiderModalOpen, setIsCreateGuiderModalOpen,
            passengerPlanId, setPassengerPlanId,
            currentDay, setCurrentDay,
            endDay, setEndDay,
            assignTripReservationId, setAssignTripReservationId,
            assignTripTourId, setAssignTripTourId,
            traspassTripReservationId, setTraspassTripReservationId,
            traspassTripTourId, setTraspassTripTourId,
            registerPassengersTripId, setRegisterPassengersTripId,
            registerCostsTripId, setRegisterCostsTripId,
            requirementsTripId, setRequirementsTripId,
            accountingSellerId, setAccountingSellerId,
            editTripId, setEditTripId,
            relationshipAgencyId, setRelationshipAgencyId,
            editReservationId, setEditReservationId,
            editPassengerData, setEditPassengerData,
            editTourData, setEditTourData,
            editDriverData, setEditDriverData,
            editGuiderData, setEditGuiderData,
            editSellerData, setEditSellerData,
            editAdminData, setEditAdminData,
            editAgencyData, setEditAgencyData,
            addPaymentData, setAddPaymentData,
            reservationDetails, setReservationDetails,
            viewPaymentAgencyId, setViewPaymentAgencyId,
            editTourInfoId, setEditTourInfoId,
        }
    }>{children}</AdminProvider.Provider>;
}

export const useAdmin = () => {
    return useContext(AdminProvider)
}